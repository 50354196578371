<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
    <vs-select :disabled="disabled" :placeholder="placeholder" class="w-full" :label="name" multiple autocomplete
      v-model="computedValue">
      <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item, index) in options" />
    </vs-select>
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TmMultiSelect",
  props: {
    disabled: { type: Boolean, required: false, default: false },
    name: { type: String, required: false, default: null },
    value: { type: Array, required: true, nullable: true, default: [] },
    options: { type: Array, required: true },
    placeholder: { type: String, default: null },
    validationRules: { type: [String, Object], required: false, default: '' }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
};
</script>
