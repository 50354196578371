<template>
  <div>
    <ag-table :data-source-api="getAll" :grid-options="gridOptions" :permissions="permissions" :rtl="false"
      @emitAddNewRecord="createRecord()" class="test-table">
      <template v-slot:action>
        <div v-if="availableAreas.length > 0" class="areas-alert-container">
          <vs-alert color="warn" class="areas-alert">
            <img src="@/assets/images/icons/alerts/alert-warn-icon.svg" width="30" height="30" />
            <p>
              المناطق التالية لم يسند لها جابي بعد : <span class="districts">{{ getJoinedJabiDistricts(availableAreas)
              }}</span>
            </p>
          </vs-alert>
        </div>
      </template>
    </ag-table>
    <tm-popup :submit="onSubmit" :title="dialogTitle" ref="jabiPopup">
      <div class="vx-row justify-start">
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="اسم الجابي الاول" placeholder="اسم الجابي" type="text"
            validation-rules="required" v-model="jabiDetails.first_name" />
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="اسم الجابي الاخير" placeholder="اسم الجابي الاخير" type="text"
            validation-rules="required" v-model="jabiDetails.last_name" />
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="رقم الجابي" placeholder="رقم الجابي" type="number"
            :validation-rules="{ required: true, regex: /^((9639)|(09))[0-9]{8}$/ }" v-model="jabiDetails.mobile" />
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-multi-select :disabled="!jabiDetails.is_active" class="w-full" name="المنطقة" placeholder="اختر المنطقة"
            :options="availableAreas" v-model="jabiDetails.district_ids" />
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="كلمة المرور" placeholder="كلمة المرور" type="password"
            :validation-rules="dialogTitle != 'تعديل معلومات الجابي' ? 'required' : ''" v-model="jabiDetails.password" />
        </div>

        <div v-if="dialogTitle != 'تعديل معلومات الجابي'" class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <label for="">
            <span class="font-normal text-sm">حالة الجابي</span>
          </label>
          <div class="py-2">
            <vs-switch v-model="jabiDetails.is_active" icon-pack="feather" vs-icon="icon-check" />
          </div>
        </div>
      </div>
    </tm-popup>
    <vs-popup title="" :active.sync="deactiveJabiDialogData.open" :fullscreen="false">
      <div class="w-full flex justify-center items-stretch">
        <img src="@/assets/images/icons/alerts/deactive-jabi-icon.svg" width="100" height="100">
      </div>
      <div class="pb-8 pt-6 text-center">
        <p class="text-xl font-bold">هل تريد تعطيل الجابي؟</p>
      </div>
      <div v-if="deactiveJabiDialogData.jabi" class="w-full flex justify-center items-stretch gap-4">
        <vs-button color="danger" type="filled" @click="deactiveJabi">
          تعطيل
        </vs-button>
        <vs-button color="primary" type="filled" @click="deactiveJabiDialogData.open = false">
          إلغاء
        </vs-button>
      </div>
    </vs-popup>
    <vs-popup title="لا يمكن تعطيل الجابي" class="deactive-jabi-faild-popup"
      :active.sync="deactiveJabiDialogFaildData.open" :fullscreen="false">
      <div v-if="deactiveJabiDialogFaildData.jabi" class="px-2">
        <div class="pb-5">
          <p class="text-lg font-semibold pb-3">الجابي لديه المناطق التالية المرتبط فيها</p>
          <div style="background: #F4F4F4" class="rounded-lg py-2 px-3 flex justify-between items-center gap-2">
            <p class="text-lg">
              {{ getJoinedJabiDistricts(deactiveJabiDialogFaildData.jabi.districts || []) }}
            </p>
            <vs-button :disabled="deactiveJabiDialogFaildData.jabi.districts.length <= 0"
              @click="deactiveJabiDistrictsEdit(deactiveJabiDialogFaildData.jabi)" color="primary"
              type="filled">تعديل</vs-button>
          </div>
        </div>

        <div class="pb-8">
          <p class="text-lg font-semibold pb-3">الجابي لديه طلبات تبرع مفتوحة</p>
          <div style="background: #F4F4F4" class="rounded-lg py-2 px-3 flex justify-between items-center gap-2">
            <p class="text-lg">{{ deactiveJabiDialogFaildData.orders }} طلب تبرع مفتوح</p>
            <vs-button :disabled="deactiveJabiDialogFaildData.orders <= 0"
              @click="deactiveJabiOrdersEdit(deactiveJabiDialogFaildData.jabi, deactiveJabiDialogFaildData.orders)"
              color="primary" type="filled">تعديل</vs-button>
          </div>
        </div>

        <div class="text-center pb-2">
          <p class="text-lg">الرجاء فك ارتباط المناطق و اسناد التبرعات المفتوحة لجابي آخر</p>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { RepositoryFactory } from '../jabi-repositories/jabiRepositoryFactory';
import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
import AgButton from "../../shared/shared-components/ag-grid/AgButton";
import AgTableSwitchCell from "../../shared/shared-components/ag-grid/AgTableSwitchCell.vue";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmMultiSelect from "@/app/shared/shared-components/TmMultiSelect";
import { environment } from "@/environment";


const jabiRepo = RepositoryFactory.get('jabiesRepository');
export default {
  name: "jabiDetails",
  components: {
    AgTable,
    TmInput,
    TmMultiSelect,
    AgTableBtnCell,
    TmPopup,
    AgButton,
    AgTableSwitchCell
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      dialogTitle: '',
      deactiveJabiDialogData: {
        jabi: null,
        open: false,
      },
      deactiveJabiDialogFaildData: {
        jabi: null,
        orders: 0,
        open: false,
      },
      jabiDetails: {
        mobile: null,
        district_ids: [],
        password: null,
        first_name: null,
        last_name: null,
        is_active: false,
      },
      availableAreas: [],
      permissions: {
        add: 'store_jabi',
        edit: 'update_jabi'
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          checkboxSelection: true,
          filter: 'agNumberColumnFilter',
          width: 150,
          sortable: true
        },
        {
          headerName: 'الاسم الاول',
          field: 'first_name',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: true
        },
        {
          headerName: 'الاسم الاخير',
          field: 'last_name',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: true
        },
        {
          headerName: 'الرقم',
          field: 'mobile',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: true
        },
        {
          headerName: 'المنطقة',
          valueGetter:
            function getObject(params) {
              return params.data.districts.map(x => x.name).join('-');
            },
          field: 'districts',
          sortable: false
        },
        {
          headerName: 'حالة الجابي',
          cellRendererFramework: AgTableSwitchCell,
          valueGetter:
            function getObject(params) {
              return params;
            },
          cellRendererParams: params => ({
            async switchAction(valueGetter) {
              if (!valueGetter.project.is_active) {
                self.changeJabiActiveStatus(valueGetter, 1);
                return;
              }

              try {
                const jabiActivationResponse = await jabiRepo.checkJabiActivation(valueGetter.id);

                if (typeof jabiActivationResponse.data === 'string') {
                  self.deactiveJabiDialogData = {
                    jabi: {
                      ...valueGetter,
                    },
                    open: true,
                  };
                  return;
                }
              } catch (err) {
                const response = JSON.parse(err.request.response);
                if (response.districts.length > 0 || response.donationsCount > 0) {
                  self.deactiveJabiDialogFaildData = {
                    open: true,
                    orders: response.donationsCount,
                    jabi: {
                      ...valueGetter,
                    },
                  }
                }
              }
            },
            data: {
              checked: !!params.data.project.is_active
            }
          }),
          sortable: false,
        },
        {
          headerName: 'طلبات التبرع الحالية',
          cellRendererFramework: AgButton,
          valueGetter:
            function getObject(params) {
              return params
            },
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'التبرعات الحالية',
            type: 'gradient',
            click(valueGetter) {
              self.onOrdersClick(valueGetter.data.id, 'PENDING');
            }
          },
          sortable: false,
        },
        {
          headerName: 'طلبات التبرع المنجزة',
          cellRendererFramework: AgButton,
          valueGetter:
            function getObject(params) {
              return params;
            },
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'التبرعات المنجزة',
            type: 'gradient',
            click(valueGetter) {
              self.onOrdersClick(valueGetter.data.id, 'DONE');
            }
          },
          sortable: false,
        },
        {
          headerName: 'الإجراءات',
          valueGetter:
            function getObject(params) {
              return params.data.id;
            },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            editRecord: function (jabiDetails) {
              self.editRecord(jabiDetails);
            },
            actions: ['edit'],
            permissions: self.permissions
          },
          width: 50,
          sortable: false
        },
      ];
    },
    getJoinedJabiDistricts(districts) {
      return districts.length <= 0 ? 'لا يوجد' : districts.map(district => district.name).join(" - ")
    },
    async changeJabiActiveStatus(jabiDetails, status) {
      if (!jabiDetails) return;

      const jabiPayload = {
        id: jabiDetails.id,
        mobile: jabiDetails.mobile,
        district_ids: (jabiDetails.districts || []).map(district => district.id),
        password: jabiDetails.password,
        first_name: jabiDetails.first_name,
        last_name: jabiDetails.last_name,
        is_active: status,
      };

      await jabiRepo.updateJabi(jabiPayload);
      await this.gridApi.refreshServerSideStore({ purge: true });
    },
    async deactiveJabi() {
      if (!this.deactiveJabiDialogData.jabi) return;
      this.deactiveJabiDialogData.open = false;
      await this.changeJabiActiveStatus(this.deactiveJabiDialogData.jabi, 0);
    },
    deactiveJabiOrdersEdit(jabiDetails, jabiOrders) {
      if (jabiOrders.length <= 0) return;
      this.onOrdersClick(jabiDetails.id, 'PENDING');
    },
    deactiveJabiDistrictsEdit(jabiDetails) {
      if (jabiDetails.districts.length <= 0) return;
      this.deactiveJabiDialogFaildData.open = false;
      this.editRecord(jabiDetails);
    },
    resetJabiDetails() {
      Object.keys(this.jabiDetails).forEach(key => {
        if (key === 'is_active') this.jabiDetails[key] = false;
        else if (key !== 'district_ids') this.jabiDetails[key] = null;
        else this.jabiDetails[key] = [];
      });
    },
    createRecord() {
      this.dialogTitle = 'إضافة جابي جديد';
      this.resetJabiDetails();
      this.$refs.jabiPopup.open();
    },
    editRecord(jabiDetails) {
      if (!jabiDetails) return;

      const districtIds = [];
      for (const district of jabiDetails.districts || []) {
        districtIds.push(district.id);
        const item = this.availableAreas.find(item => item.id === district.id);
        if (!item) {
          this.availableAreas.push({ ...district })
        }
      }
      const currentAreas = structuredClone(this.availableAreas);
      this.availableAreas = currentAreas.reverse();

      this.jabiDetails = {
        id: jabiDetails.id,
        mobile: jabiDetails.mobile,
        district_ids: districtIds,
        password: jabiDetails.password,
        first_name: jabiDetails.first_name,
        last_name: jabiDetails.last_name,
        is_active: jabiDetails.project.is_active,
      };
      this.dialogTitle = 'تعديل معلومات الجابي';
      this.$refs.jabiPopup.open();
    },
    async onSubmit() {
      let response;
      try {
        if (this.dialogTitle == 'تعديل معلومات الجابي') {
          response = await jabiRepo.updateJabi(this.jabiDetails);
        } else {
          response = await jabiRepo.createJabi({
            ...this.jabiDetails,
            is_active: jabi.is_active ? 1 : 0,
            district_ids: jabi.is_active ? this.jabiDetails.district_ids : [],
          });
          this.resetJabiDetails();
        }

        if (response.status === 200) {
          this.$refs.jabiPopup.endLoading();
          this.$refs.jabiPopup.close();
          await this.fetchAllJabiesDistricts();
          await this.gridApi.refreshServerSideStore({ purge: true });
        }
      } catch (err) {
        this.$refs.jabiPopup.endLoading();
      }
    },
    async getAll(query) {
      return jabiRepo.fetchAllJabies(query);
    },
    async fetchAllJabiesDistricts() {
      this.availableAreas = await jabiRepo.fetchAllJabiesDistricts();
    },
    onOrdersClick(id, status) {
      const URL = `${environment.apiBaseURL}`;
      const env = (URL.includes('staging') ? 'https://dev.tamayoz-kareem-cms.pages.dev' : 'https://kareem-cms.t-tamayoz.com');
      const page = status === 'PENDING' ? 'active-donations' : 'archive-donations';
      window.open(`${env}/#/department-jabies/${page}?filter=jabiId||$eq||${id}`);
    },
  },
  created() {
    this.fetchAllJabiesDistricts();
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style>
.areas-alert-container {
  margin-right: auto;
}

.deactive-jabi-faild-popup .vs-popup--header,
.deactive-jabi-faild-popup .vs-popup--header .vs-popup--title {
  background-color: #D89814 !important;
}

.areas-alert {
  color: #D89814 !important;
  border: 2px solid #D89814 !important;
  background: #FFFAF1 !important;
  height: auto !important;
}

.areas-alert .vs-alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-weight: 600 !important;
}

.areas-alert .vs-alert .districts {
  font-weight: 400 !important;
}
</style>